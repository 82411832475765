import { webpPrefix } from "utils/webpPrefix";

interface ImageFile {
  image_fallback: string;
  image_width: number;
  image_height: number;
}

export function imageFallback(f: ImageFile) {
  return {
    src: `${webpPrefix}${f.image_fallback}`,
    width: f.image_width,
    height: f.image_height,
  };
}
