import find from "lodash/find";
import { CurrencyFragment } from "@trunkery/internal/lib/vature-gen/types";
import { defaultCurrencyInfo, formatCurrency } from "@lana-commerce/core/formatCurrency";

export function formatCurrencyFromList(currencyCode: string, currencies: CurrencyFragment[], value: number) {
  const c = find(currencies, (c) => c.currency.code === currencyCode);
  return formatCurrency(c || defaultCurrencyInfo, value);
}

export function currencyFormatterFor(currencyCode: string, currencies: CurrencyFragment[]) {
  return (v: number) => formatCurrencyFromList(currencyCode, currencies, v);
}
