import { VariantFragment } from "@trunkery/internal/lib/vature-gen/types";

export interface VariantPriceInfo {
  sale: boolean;
  hide: boolean;
  price: number;
  prevPrice: number;
}

// customer groups?
export function variantPriceInfo(variant: VariantFragment): VariantPriceInfo {
  const saleEligible = variant.sale_price_eligible;
  if (variant.sale_price !== 0 && saleEligible) {
    return {
      sale: true,
      price: variant.sale_price,
      prevPrice: variant.price,
      hide: variant.sale_price < variant.map_price,
    };
  } else {
    return {
      sale: false,
      price: variant.price,
      prevPrice: variant.retail_price,
      hide: variant.price < variant.map_price,
    };
  }
}
