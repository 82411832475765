import React, { useEffect, useRef, useState } from "react";
import { nonNull } from "@lana-commerce/core/nonNull";
import { transparentPixel } from "utils/transparentPixel";
import { useInView } from "react-intersection-observer";

function niceImageName(v: LazyImageProps) {
  if (v.src) return v.src;
  if (v.srcSet) {
    return v.srcSet
      .replace(/\n/g, " ") // replace new lines with space
      .replace(/ +(?= )/g, "") // remove duplicate spaces
      .replace(/^ */, "") // remove spaces from the beginning of the string
      .replace(/ *$/, ""); // remove trailing spaces
  }
}

const LAZY_LOAD = true;
const DEBUG_MODE = false;
const DEBUG_LOG = (props: LazyImageProps, str: string) => {
  if (typeof document === "undefined") return;
  if (!DEBUG_MODE) return;
  console.log(`${niceImageName(props)} ${str}`);
};

interface LazyImageProps {
  src?: string;
  srcSet?: string;
  sizes?: string;
  fallback?: {
    src: string;
    width: number; // this is original image width
    height: number; // this is original image height
  };
  fallbackColor?: {
    color: string; // color in hex form
    width: number; // this is original image width
    height: number; // this is original image height
  };

  // this is a size for lazy image container, not the image itself
  width?: number;
  height?: number;
  // don't set width/height on style, but use those to set aspect ratio hack
  setAspectRatio?: boolean;
  className?: string;
  alt?: string;
  title?: string;
}

export const LazyImage = (props: LazyImageProps) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [ref, inView] = useInView({ triggerOnce: true });
  const [isLoaded, setIsLoaded] = useState(false);

  if (!props.src) {
    if (!props.srcSet || !props.sizes) {
      throw new Error("if src is not set, srcSet and sizes must be present");
    }
  }

  const { fallback, fallbackColor } = props;
  if (!fallback && !fallbackColor) {
    throw new Error("fallback or fallbackColor must be set");
  }

  // load image once it's in view
  useEffect(() => {
    const img = imageRef.current;
    // only load image if the state is appropriate
    if (LAZY_LOAD && inView && !isLoaded && img && img.src !== transparentPixel) {
      DEBUG_LOG(props, `in view`);
      // "as any" here to kill types, otherwise typescript thinks "decode" is always a part of the image
      // and thinks else branch can never be triggered
      if ("decode" in (img as any)) {
        img.decode().then(() => {
          DEBUG_LOG(props, `loaded (decode)`);
          if (imageRef.current) setIsLoaded(true);
        });
      } else {
        img.onload = () => {
          DEBUG_LOG(props, `loaded (onload)`);
          if (imageRef.current) setIsLoaded(true);
        };
      }
    }
  }, [inView]);

  DEBUG_LOG(props, `render [loaded: ${isLoaded}, view: ${inView}]`);

  const containerStyle =
    props.width && props.height && props.setAspectRatio
      ? {
          paddingBottom: `${(props.height / props.width) * 100}%`,
        }
      : props.width || props.height
      ? {
          width: props.width,
          height: props.height,
        }
      : undefined;

  const containerClass = props.className ? `lazy-image-container ${props.className}` : "lazy-image-container";
  const commonProps = {
    alt: props.alt,
    title: props.title,
    width: fallback ? fallback.width : nonNull(fallbackColor, "fallbackColor").width,
    height: fallback ? fallback.height : nonNull(fallbackColor, "fallbackColor").height,
  };

  return (
    <div className={containerClass} style={containerStyle}>
      {props.fallback ? (
        <img {...commonProps} ref={ref} src={props.fallback.src} />
      ) : (
        <div ref={ref} title={props.title} style={{ backgroundColor: nonNull(fallbackColor, "fallbackColor").color }} />
      )}
      <img
        {...commonProps}
        ref={imageRef}
        decoding="sync"
        src={inView ? props.src : transparentPixel}
        srcSet={inView ? props.srcSet : undefined}
        sizes={inView ? props.sizes : undefined}
        className={isLoaded ? "lazy-image-loaded" : undefined}
      />
    </div>
  );
};
