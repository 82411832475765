import React from "react";
import { CurrencyFragment, ShopFragment, VariantFragment } from "@trunkery/internal/lib/vature-gen/types";
import { formatCurrencyFromList } from "utils/formatCurrencyFromList";
import { isSoldOut } from "utils/isSoldOut";
import { variantPriceInfo } from "utils/variantPriceInfo";

import { T } from "./VariantPrice.tlocale";

interface VariantPriceProps {
  variant: VariantFragment;
  shop: ShopFragment;
  currencies: CurrencyFragment[];
}

export const VariantPrice = (props: VariantPriceProps) => {
  const { shop, currencies } = props;
  const soldOut = isSoldOut(props.variant.inventory);
  const priceInfo = variantPriceInfo(props.variant);
  const fmt = (v: number) => formatCurrencyFromList(shop.currency, currencies, v);
  const specialStyle = { color: "#d73d3d" };
  const soldOutStyle = { color: "#585757" };
  if (soldOut) {
    return <span style={soldOutStyle}>{T("Sold out")}</span>;
  }
  if (priceInfo.hide) {
    return <span style={specialStyle}>{T("Price too low to show")}</span>;
  }
  if (priceInfo.price < priceInfo.prevPrice) {
    return (
      <>
        <s>{fmt(priceInfo.prevPrice)}</s> <span style={specialStyle}>{fmt(priceInfo.price)}</span>
      </>
    );
  }
  return <>{fmt(priceInfo.price)}</>;
};
