import React from "react";
import { FileFragment } from "@trunkery/internal/lib/vature-gen/types";
import { ImageParams, convertImageURL } from "@lana-commerce/core/convertImageURL";
import { LazyImage } from "components/LazyImage";
import { imageFallback } from "utils/imageFallback";
import { useSiteData } from "utils/useSiteData";

interface FlippingImagesProps {
  images: FileFragment[];
  width: number;
  height: number;
  crop?: ImageParams["crop"];
}

export const FlippingImages = (props: FlippingImagesProps) => {
  const { config } = useSiteData();
  const { images, width, height } = props;
  const size = `${width}x${height}`;
  const imageParams = {
    size,
    crop: props.crop,
  };
  return (
    <>
      {images.length >= 2 ? (
        <>
          <LazyImage
            src={convertImageURL(config.cdn, images[1].public_url, imageParams)}
            fallback={imageFallback(images[1])}
            className="img"
            alt={images[1].image_alt}
            title={images[1].image_alt}
          />
          <LazyImage
            src={convertImageURL(config.cdn, images[0].public_url, imageParams)}
            fallback={imageFallback(images[0])}
            className="img img-crossfade"
            alt={images[0].image_alt}
            title={images[0].image_alt}
          />
        </>
      ) : (
        <LazyImage
          src={convertImageURL(config.cdn, images[0].public_url, imageParams)}
          fallback={imageFallback(images[0])}
          className="img"
          alt={images[0].image_alt}
          title={images[0].image_alt}
        />
      )}
    </>
  );
};
