import React from "react";
import { CurrencyFragment, ShopFragment, VariantFragment } from "@trunkery/internal/lib/vature-gen/types";
import { formatCurrencyFromList } from "utils/formatCurrencyFromList";
import { isSoldOut } from "utils/isSoldOut";
import { variantPriceInfo } from "utils/variantPriceInfo";

import { T } from "./SaveBadge.tlocale";

interface SaveBadgeProps {
  variant: VariantFragment;
  shop: ShopFragment;
  currencies: CurrencyFragment[];
}

export const SaveBadge = (props: SaveBadgeProps) => {
  const { shop, currencies } = props;
  const soldOut = isSoldOut(props.variant.inventory);
  const priceInfo = variantPriceInfo(props.variant);
  return !soldOut && !priceInfo.hide && priceInfo.prevPrice > priceInfo.price ? (
    <div className="price-badge">
      {T("SAVE {amount}", {
        amount: formatCurrencyFromList(shop.currency, currencies, priceInfo.prevPrice - priceInfo.price),
      })}
    </div>
  ) : null;
};
