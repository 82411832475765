interface ProductLike {
  custom_fields: {
    name: string;
    value: string;
  }[];
}

export function getCustomFields<T>(p: ProductLike, name: string): T[] {
  const out: T[] = [];
  for (const f of p.custom_fields) {
    if (f.name === name) {
      try {
        out.push(JSON.parse(f.value) as T);
      } catch {
        // do nothing
      }
    }
  }
  return out;
}

export function getCustomField<T>(p: ProductLike, name: string): T | undefined {
  const fields = getCustomFields<T>(p, name);
  return fields.length > 0 ? fields[0] : undefined;
}
